import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { lastValueFrom } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class LiveUpdateService {

  private platform = inject(Platform);
  private httpClient = inject(HttpClient);
  private settingsService = inject(SettingsService);

  constructor() { }

  async checkForUpdates() {
    if (!this.platform.is('capacitor')) {
      return;
    }

    try {
      // Notify Capacitor Updater that the current bundle is working (a rollback will occur
      // if this method is not called on every app launch) By default this method should be
      // called in the first 10 sec after app launch, otherwise a rollback will occur.
      await LiveUpdate.ready();

      // Get current bundle information
      const { bundleId } = await LiveUpdate.getCurrentBundle();

      // Check for new versions
      const response = await this.getLatestVersion(bundleId);

      // Stop if server responds with {}
      if (!Object.keys(response).length) {
        console.log('OTA: No Update available');
        return;
      }

      // Users that activated beta setting or using the TestFlight are eligible
      const isBetaTester = this.settingsService.userIsBetaTester$.value;
      if (!response.prerelease || (response.prerelease && isBetaTester)) {
        console.log('OTA Update available: ' + response.version);
        await this.downloadUpdate(response.archive, response.version, response.mandatory);
      }
    } catch (error) {
      // alert('In-App-Update Error: ' + error.message);
      console.error('In-App-Update Error:', error);
    }
  }

    /**
   * Downloads and installs the update
   *
   * @param url link to the zip archive
   * @param version current version of the update
   */
    private async downloadUpdate(url: string, version: string, mandatory?: boolean): Promise<void> {

      // Check if version was downloaded before
      const { bundleIds } = await LiveUpdate.getBundles();
      let alreadyDownloaded = bundleIds.some(id => id === version);
  
      if (!alreadyDownloaded) {
        await LiveUpdate.downloadBundle({
          url,
          bundleId: version
        });

        await LiveUpdate.setNextBundle({
          bundleId: version
        });
      }
    }

  /**
   * Fetch latest in-app-update version from api
   */
  private async getLatestVersion(currentVersion: string): Promise<UpdateServerResponse> {
    try {
      const request = this.httpClient.get('https://api.clickyourapp.com/v1/enterprise/apps/de.btcecho.app/update-check/?currentVersion=' + currentVersion);
      const response = await lastValueFrom(request) as Promise<UpdateServerResponse>;
      return response;
    } catch (error) {
      console.error('Error fetching update:', error);
      return {} as UpdateServerResponse;
    }
  }
}

interface UpdateServerResponse {
  id: number; // Unique asset id from GitHub
  version: string; // App Version
  archive: string; // Download Link
  size: number; // Size in byte
  downloads: number; // Download count
  mandatory: boolean; // Force update?
  prerelease: boolean; // Is it only for beta testers?
};
