/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CacheService } from 'ionic-cache';
import { Observable, catchError, from, lastValueFrom, map, of, switchMap, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

// Cache TTL
const ONE_HOUR = 60 * 60;
const SIX_HOURS = ONE_HOUR * 6;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const THIRTY_MIN = ONE_HOUR / 2;

@Injectable({
  providedIn: 'root'
})
export class SanityService {

  private httpClient = inject(HttpClient);
  private cacheService = inject(CacheService);
  private platform = inject(Platform);
  private sanityUrl = `https://${environment.sanity.projectId}.api.sanity.io/v2021-06-07/data/query/${environment.sanity.dataset}`;

  sendRequest(query: string, groupKey: string, ttl: number, forceRefresh?: boolean): Observable<any> {

    if (forceRefresh) {
      console.log(`Force refresh is enabled for group "${groupKey}". Clearing cache.`);
      return from(this.cacheService.clearGroup(groupKey)).pipe(
        tap(() => console.log(`Cache group "${groupKey}" cleared.`)),
        switchMap(() => {
          console.log(`Creating new HTTP request after cache clear.`);
          const newRequest = this.httpClient.post(this.sanityUrl, { query }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${environment.sanity.token}`
            }
          }).pipe(
            catchError(error => {
              // Return null observable on error
              console.log('Error in Sanity HTTP Request:', error);
              return of(null);
            })
          );
          console.log(`Loading data from observable.`);
          return this.cacheService.loadFromObservable(query, newRequest, null, ttl);
        })
      );
    } else {
      console.log(`Force refresh is not enabled. Loading data from cache or making network request.`);
      const request = this.httpClient.post(this.sanityUrl, { query }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${environment.sanity.token}`
        }
      }).pipe(
        catchError(error => {
          // Return null observable on error
          console.log('Error in Sanity HTTP Request:', error);
          return of(null);
        })
      );
      return this.cacheService.loadFromObservable(query, request, null, ttl);
    }
  }

  getNavigationItems(forceRefresh?: boolean): Observable<NavigationItem[]> {
    const query = `*[_type == "doc.mobileAppSettings" && _id == "mobileAppSettings"][0]{
      "updated": _updatedAt,
      "appCategoryNavigation": appCategoryNavigation->itemsSection[]{
          title,
          url
      }
    }`;

    return this.sendRequest(query, 'menu', ONE_WEEK, forceRefresh)
      .pipe(map(resp => resp?.result?.appCategoryNavigation));
  }

  async getMorePageItems(forceRefresh?: boolean): Promise<MorePageItem[]> {
    const query = `*[_type == "doc.mobileAppSettings" && _id == "mobileAppSettings"][0]{
      "updated": _updatedAt,
      "appMenuMore": appMenuMore[]{
        _key,
        _type,
        title,
        description,
        "icon": icon{
          asset->{url}
        },
        target,
        inAppBrowser,
        platform,
        minAppVersion,
        "url": coalesce(url, '/app')
      }
    }`;

    const request = this.httpClient.post(this.sanityUrl, { query }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${environment.sanity.token}`
      }
    });

    const response = await lastValueFrom(request) as any;

    return response?.result?.appMenuMore;
  }

  getAffiltateLinks(forceRefresh?: boolean): Observable<{ calculator: AffiltateLinks[]; fallbackCalculator: AffiltateLinks }> {
    const query = `*[_id == "advPremiumApp"][0]{
      "calculator": calculator[]{
        startDate,
        endDate,
        "affiliateLink": provider->affiliateData.affiliateAppCalculator.affiliateLink,
        "affiliateRemark": pt::text(provider->affiliateData.affiliateAppCalculator.affiliateRemark),
        "name": provider->name,
        "providerType": provider->providerType,
        "mediaItem": provider->mediaItem{
          asset->{
            ...,
            url,
          }
        }.asset.url,
      },
      "fallbackCalculator": fallbackCalculator->{
        "affiliateLink": affiliateData.affiliateAppCalculator.affiliateLink,
        "affiliateRemark": pt::text(affiliateData.affiliateAppCalculator.affiliateRemark),
        "name": name,
        "providerType": providerType,
        "mediaItem": mediaItem{
          asset->{
            ...,
            url,
          }
        }.asset.url,
      }
    }`;

    return this.sendRequest(query, 'calculator', ONE_HOUR, forceRefresh)
      .pipe(map(resp => resp?.result));
  }

  getTippBox(forceRefresh?: boolean): Observable<{ tippbox: TippBoxLink[]; fallbackTippbox: TippBoxLink }> {
    console.log('getTippBox ---');
    const query = `*[_id == "advPremiumApp"][0]{
      "tippbox": tippbox01[]{
        startDate,
        endDate,
        showBatch,
        "affiliateLink": provider->affiliateData.affiliateAppTippBox.affiliateLink,
        "affiliateRemark": pt::text(provider->affiliateData.affiliateAppTippBox.affiliateRemark),
        "items": provider->affiliateData.tippBox.items,
        "label": provider->affiliateData.tippBox.label,
        provider->{
          name,
          providerType,
          "mediaItem": mediaItem{
            asset->{
              ...,
              url,
            }
          }.asset.url,
          "ratingData": *[_type == "doc.review" && references(^._id)][0]{
            "count": reviewCount,
            "averageTotal": averageRating,
          }
        }
      },
      // New tipp box (with different data)
      "tippbox2": tippbox02[]{
        startDate,
        endDate,
        showBatch,
        "affiliateLink": provider->affiliateData.affiliateAppTippBox.affiliateLink,
        "affiliateRemark": pt::text(provider->affiliateData.affiliateAppTippBox.affiliateRemark),
        "items": provider->affiliateData.tippBox.items,
        "label": provider->affiliateData.tippBox.label,
        provider->{
          name,
          providerType,
          "mediaItem": mediaItem{
            asset->{
              ...,
              url,
            }
          }.asset.url,
          "ratingData": *[_type == "doc.review" && references(^._id)][0]{
            "count": reviewCount,
            "averageTotal": averageRating,
          }
        }
      },
      // Existing fallback in case no data is present
      "fallbackTippbox": fallbackTippbox01->{
        "affiliateLink": affiliateData.affiliateAppTippBox.affiliateLink,
        "affiliateRemark": pt::text(affiliateData.affiliateAppTippBox.affiliateRemark),
        "items": affiliateData.tippBox.items,
        "label": affiliateData.tippBox.label,
        "provider": {
          "name": name,
          "providerType": providerType,
          "mediaItem": mediaItem{
            asset->{
              ...,
              url,
            }
          }.asset.url,
          "ratingData": *[_type == "doc.review" && references(^._id)][0]{
            "count": reviewCount,
            "averageTotal": averageRating
          }
        }
      }
    }`;  

    return this.sendRequest(query, 'tipp-box', THIRTY_MIN, true)
      .pipe(map(resp => resp?.result));
  }

  getCtaPlacements(forceRefresh?: boolean): Observable<CtaApp> {
    const query = `*[_type == "doc.ctaPlacements" && _id == 'ctaPlacements']{
      "ctaApp": ctaApp{
        _type,
        subtitle,
        title,
        coverImage,
        description,
        ctaAction,
      }
    }`;

    return this.sendRequest(query, 'custom-cta', THIRTY_MIN, forceRefresh)
      .pipe(map(resp => resp?.result?.shift()?.ctaApp));
  }

  getNewsBreakers(forceRefresh?: boolean): Observable<AppData> {
    const query = `*[ _id=="mobileAppSettings" ][0]{
      "appHomeScreen": appHomeScreen,
      "appSinglePostScreen": appSinglePostScreen,
    }`;

    return this.sendRequest(query, 'breaker-placement', THIRTY_MIN, forceRefresh)
      .pipe(map(resp => resp?.result));
  }

  /**
   * Returns the full path to the image on the sanity cdn
   *
   * @param ref Sanity Image Reference
   * @returns CDN URL
   */
  getSanityImage(ref: string): string {
    const sanityBaseUrl = `https://cdn.sanity.io/images/${environment.sanity.projectId}/${environment.sanity.dataset}`;
    const [type, imageId, size, extension] = ref?.split('-');
    return `${sanityBaseUrl}/${imageId}-${size}.${extension}`;
  }

}

interface NavigationItem {
  title: string;
  url: string;
}

export interface MorePageItem {
  title: string;
  icon: {
    asset: {
      url: string;
    };
  };
  inAppBrowser: boolean;
  description: string;
  open: 'extern' | 'app';
  platform: 'ios' | 'android';
  minAppVersion: string;
  url: string;
}

export interface AffiltateLinks {
  affiliateLink: string;
  affiliateRemark: string;
  name: string;
  providerType: string;
  mediaItem: string;
  startDate: string;
  endDate: string;
}

export interface TippBoxLink {
  affiliateLink: string;
  affiliateRemark: string;
  label: string;
  items: string[];
  provider: Provider;
  startDate: string;
  endDate: string;
}

export interface Provider {
  mediaItem: string;
  name: string;
  providerType: string;
  ratingData: {
    averageTotal: number;
    count: number;
  };
}

interface CtaAction {
  label: string;
  url: string;
  target: string;
}

interface ImageAsset {
  _ref: string;
  _type: string;
}

interface Image {
  _type: string;
  asset: ImageAsset;
}

export interface CtaApp {
  description?: string;
  ctaAction: CtaAction;
  _type: string;
  subtitle: string;
  title: string;
  coverImage: Image;
}

interface AppData {
  appHomeScreen: Breaker[];
  appSinglePostScreen: Breaker[];
}

export type Breaker = AppCtaModule | AppPodcastModule | AppCryptoModule | AppSimilarNewsModule | AppCtaModule | AppPodcastModule | AppReviewModule | any;

interface AppCtaModule {
  _type: 'obj.appCtaModule';
  variant: string;
  _key: string;
  cta: {
    ctaAction: {
      target: string;
      label: string;
      url: string;
    };
    coverImage: Image;
    subtitle: string;
    _type: 'obj.ctaApp';
    description: string;
    title: string;
  };
}

interface AppPodcastModule {
  _type: 'obj.appPodcastModule';
  label: string;
  _key: string;
  title: string;
}

interface AppCryptoModule {
  title: string;
  numberOfCourses: number;
  _type: 'obj.appCryptoModule';
  label: string;
  _key: string;
}

interface AppSimilarNewsModule {
  _key: string;
  title: string;
  _type: 'obj.appSimilarNewsModule';
}

interface AppReviewModule {
  _key: string;
  title: string;
  _type: 'obj.appReviewModule';
}
